<template>
  <div>

    <div class="mb-3 w-100 shadow-sm bg-white rounded-lg">
      <div class="col-12 text-left p-3 border-bottom">
        <h5 class="mb-0">{{$t('recentBookings')}}</h5>
      </div>

      <div class="w-100 height250" v-if="state==='loading'">
        <div-loader />
      </div>
      <div class="w-100 height250" v-else-if="state==='error'">
        <error-div :message="stateMessage" />
      </div>
      <div class="w-100" v-else>
        <my-orders v-for="(order,index) in orders" :key="'singleOrder'+index" :order="order"/>
      </div>
      <div class="col-12 text-center p-3">
        <router-link to="/profile/orders" class="btn btn-yellow">
          {{$t('allBookings')}} <b-icon icon="arrow-right-circle-fill" font-scale="0.99"></b-icon>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import MyOrders from "@/components/Profile/Orders/MyOrders";
import DivLoader from "@/components/Template/DivLoader";
import ErrorDiv from "@/components/Template/ErrorDiv";
export default {
  name: "ProfileHome",
  components: {ErrorDiv, DivLoader, MyOrders},
  created() {
    this.recentOrders();
  },
  methods : {
    recentOrders() {
      this.state = "loading";
      this.$http.get("profile/recentOrders").then(response=>{
        if(response.data.error) {
          this.state = "error";
          this.stateMessage = response.data.message;
        } else if(response.data.error===undefined) {
          this.state = "error";
          this.stateMessage = this.$t("errors.unableToLoad");
        } else {
          this.state = "success";
          this.orders = response.data.lists;
        }
      }).catch(()=>{
        this.state = "error";
        this.stateMessage = "error";
      });
    }
  },
  data() {
    return {
      state : 'loading',
      stateMessage : "",
      orders : []
    }
  }
}
</script>

<style scoped>

</style>