<template>
    <router-link :to="'/profile/order/'+order.code" custom
                 v-slot="{ href, route, navigate}">
      <a class="d-block col-12 my-orders border-bottom p-3"
         :href="href" @click="navigate"
         :class="(order.status==='0') ? 'expiredBg' : ''">
        <div class="row text-black">
          <div class="col my-auto">
            <strong> {{order.referenceNumber}}</strong>
            <div class="w-100"></div>
            <span class="text-muted">{{order.madeOn | moment('dd, DD.MM.YYYY HH:mm')}}</span>
          </div>
          <div class="col-auto text-right">
            {{order.date | moment('dd, DD.MM.YYYY')}}
            <div class="w-100"></div>
            <strong class="text-yellow"> {{$helper.convertToCurrency(order.amount)}} </strong>
          </div>
        </div>
      </a>
    </router-link>
</template>

<script>
export default {
    name: "MyOrders",
    props : {
      order : Object,
    }
}
</script>
